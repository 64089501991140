


















































































































































































import Vue from "vue";
import {AccountService} from '@/code/services/user/accountService';
import {EventBus} from '@/code/util/eventBus';
import Swal from 'sweetalert2';
import axios2 from '@/axios2';
import {Confirm, Loading} from '@/code/util/swalUtil';
import {ALLOW_ERP_INTEGRATIONS, ALLOW_ERP_PRICE_TABLE, EErpTypes, erpTypes} from '@/code/enums/EErpTypes';
import {AxiosRequestConfigExtended} from "@/code/interfaces/AxiosRequestConfigExtended";

export default Vue.extend({

  data() {
    return {
      idAccount: 0,
      linx_empresaid: '',
      linx_cnpj: '',
      linx_portal: '',
      linxSandbox: false,
      tabelasPrecoList: [] as Array<any>,
      linx_tabela_preco_id: '',
      erp: EErpTypes.NONE,
      erpTypes: erpTypes,
      isConnecting: false,
      isConnectionSuccess: false,
      isConnectionError: false,
      connectionErrorMessage: ''
    }
  },

  mounted() {
    this.loadAccount();
  },

  methods: {
    loadAccount: async function() {
      this.idAccount = parseInt(this.$route.params.id);
      const that = this;
      if (this.idAccount && this.idAccount > 0) {
        const service = new AccountService();
        const selectedAccount = await service.get(this.idAccount, true);
        this.linx_empresaid = selectedAccount.linx_empresaid;
        this.linx_cnpj = selectedAccount.linx_cnpj;
        this.linx_portal = selectedAccount.linx_portal;
        this.linxSandbox = selectedAccount.linx_sandbox;
        this.linx_tabela_preco_id = selectedAccount.linx_tabela_preco_id;
        this.erp = EErpTypes[selectedAccount.erp];

        if (this.linx_empresaid && this.linx_cnpj && this.linx_portal) {
          this.refreshTables();
        }

      } else {
        Swal.fire({
          title: 'Parâmetro inválido',
          text: 'A loja informada é invalida!',
          icon: 'error'
        })
        .then(
          (result) => {
            that.$router.replace({ name: 'assistentAction' });
          }
        )
      }
    },
    saveAndNextTab: async function() {
      const dirty = await (this.$refs.formLinx as any).flags.dirty;
      if (dirty) {
        Loading.fire("Salvando os dados!");
        const toSave : any = {
          type: 'account',
          erp: this.erp
        };

        if (ALLOW_ERP_INTEGRATIONS.includes(this.erp)) {
          toSave.linx_empresaid = this.linx_empresaid;
          toSave.linx_cnpj = this.linx_cnpj;
          toSave.linx_portal = this.linx_portal;
          toSave.linx_sandbox = this.linxSandbox ? true : false;
          toSave.linx_tabela_preco_id = this.linx_tabela_preco_id;
        }

        const service = new AccountService();
        const saved = await service.update(this.idAccount, toSave);
        Swal.close();
        if (saved && saved.id > 0) {
          EventBus.$emit('nextAssistentRoute', this.idAccount);
        }
      } else {
        EventBus.$emit('nextAssistentRoute', this.idAccount);
      }
    },
    previousTab: async function() {
      const dirty = await (this.$refs.formLinx as any).flags.dirty;
      if (dirty) {
        Confirm.fire('Os dados informados serão perdidos. Deseja voltar à etapa anterior?')
        .then(result => {
          if (result && result.isConfirmed) {
            EventBus.$emit('previousAssistentRoute');
          }
        });
      } else {
        EventBus.$emit('previousAssistentRoute');
      }
    },
    testIntegration: async function() {
      this.isConnectionError = false;
      this.isConnectionSuccess = false;
      this.isConnecting = true;
      this.connectionErrorMessage = '';

      const that = this;
      const data = {
          erp: this.erp,
          linx_portal: this.linx_portal,
          linx_cnpj: this.linx_cnpj,
          linx_empresaid: this.linx_empresaid,
          linx_sandbox: this.linxSandbox ? true : false
        }

      const myConfig: AxiosRequestConfigExtended = { handleError: false };
      axios2.post(`accounts/test-erp`, data, myConfig)
        .then(
          (response) => {
            that.isConnecting = false;
            that.isConnectionSuccess = true;
            that.isConnectionError = false;
          },
          (error) => {
            that.isConnecting = false;
            that.isConnectionSuccess = false;
            that.isConnectionError = true;
            that.connectionErrorMessage = error.response.data.message || 'Erro ao conectar com o ERP';
          }
        )
    },
    refreshTables: async function() {
      if (!this.linx_empresaid || !this.linx_cnpj || !this.linx_portal) {
        Swal.fire('É necessário preencher os campos Empresa id, CNPJ e Portal para atualizar.');
      } else {
        Loading.fire("Atualizando dados!");
        const that = this;
        const data = {
          erp: this.erp,
          linx_portal: this.linx_portal,
          linx_cnpj: this.linx_cnpj,
          linx_empresaid: this.linx_empresaid,
          linx_sandbox: this.linxSandbox ? true : false,
        }
        axios2.post(`products/tabela-preco-linx`, data)
        .then(
          (response) => {
            that.tabelasPrecoList = new Array<any>();
            if (response.data && response.data.data && response.data.status === 'success') {
              response.data.data.forEach(el => {
                that.tabelasPrecoList.push({ id: el.value, description: el.label });
              });
            }
            Swal.close();
          },
          (error) => {
            Swal.close();
          }
        )
      }
    },
    limparTesteConexao: function() {
      this.isConnectionError = false;
      this.isConnectionSuccess = false;
      this.isConnecting = false;
    },
    isErpIntegration: function() {
      return ALLOW_ERP_INTEGRATIONS.includes(this.erp);
    }
  },

  computed: {
    empresaIdLabel() : String {
      return this.erp === EErpTypes.LINX ? "Empresa id" : "Número da loja";
    },
    cnpjLabel() : String {
      return this.erp === EErpTypes.LINX ? "CNPJ" : "Chave do integrador";
    },
    portalLabel() : String {
      return this.erp === EErpTypes.LINX ? "Portal" : "Número da instituição";
    },
    isPriceTablesVisible(): Boolean {
      return ALLOW_ERP_PRICE_TABLE.includes(this.erp);
    }
  }

});
